import { useStaticQuery, graphql } from "gatsby"

export const useLandingPageData = () => {
  const {
    contentfulLandingPage: {
      title,
      excerpt,
      image: { gatsbyImageData },
      description,
      coursePlanning,
      ourAdvantage,
      problem,
      enterprise,
      learningCircle,
      result,
      consultTitle,
      consultImage: { gatsbyImageData: consultGatsbyImageData },
      teacher,
      onlineCourse,
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulLandingPage(slug: { eq: "index" }) {
          slug
          coursePlanning {
            title
            slug
          }
          description {
            description
          }
          ourAdvantage {
            title
            excerpt
            image {
              title
              gatsbyImageData(
                width: 384
                height: 384
                placeholder: BLURRED
                quality: 100
              )
            }
          }
          problem {
            title
            excerpt
            url
          }
          title
          excerpt
          image {
            gatsbyImageData(
              width: 704
              height: 470
              placeholder: BLURRED
              quality: 100
            )
          }
          enterprise {
            title
            image {
              url
            }
          }
          learningCircle
          result {
            title
            trafficNumber
            unit
          }
          consultTitle
          consultImage {
            gatsbyImageData(
              width: 704
              height: 470
              placeholder: BLURRED
              quality: 100
            )
          }
          teacher {
            id
            slug
            name
            type
            image {
              gatsbyImageData(
                width: 176
                height: 176
                placeholder: BLURRED
                quality: 100
              )
            }
          }
          onlineCourse {
            id
            title
            image {
              gatsbyImageData(
                width: 450
                height: 248
                placeholder: BLURRED
                quality: 100
              )
            }
            slug
            topic {
              id
              name
            }
            level {
              id
              name
            }
            minute
            hours
          }
        }
      }
    `
  )

  return {
    title,
    excerpt,
    gatsbyImageData,
    description,
    coursePlanning,
    ourAdvantage,
    problem,
    enterprise,
    learningCircle,
    result,
    consultTitle,
    consultGatsbyImageData,
    teacher,
    onlineCourse,
  }
}
