import { GatsbySeo } from "gatsby-plugin-next-seo"
import PropTypes from "prop-types"
import React from "react"
import { useMetadata } from "../data/use-metadata"
import Footer from "./Footer"
import Navbar from "./Navbar"
import Modal from "./Modal"
import { useSettingData } from "../hooks/useSettingData"
import { useModal, useModalUpdate } from "../contexts/ModalContext"

const Layout = ({ children, showTitleTemplate = true }) => {
  const { title, titleTemplate, description, siteUrl, siteName, language } =
    useMetadata()

  // netlify 轉址後會帶有原本的參數，當 url 有 lang 這個參數時，代表是轉址來的網址
  if (typeof window !== "undefined") {
    const isUrlHasLang = window.location.href.includes("lang")

    if (isUrlHasLang) {
      window.history.pushState(null, null, window.location.pathname)
    }
  }

  const {
    contentfulSetting: {
      ogImage: {
        file: { url },
      },
    },
  } = useSettingData()

  const isModal = useModal()
  const setIsModal = useModalUpdate()

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={showTitleTemplate ? titleTemplate : ""}
        description={description}
        canonical={siteUrl}
        language={language}
        openGraph={{
          url: siteUrl,
          title: title,
          description: description,
          images: [
            {
              url: `https:${url}`,
              width: 1200,
              height: 630,
              alt: title,
            },
          ],
          site_name: siteName,
          type: "website",
          locale: "zh_tw",
        }}
      />
      <div>
        <Navbar isModal={isModal} />
        <main className={`${isModal ? "" : "pt-20"}`}>{children}</main>
        {!isModal && (
          <a
            rel="noopener noreferrer"
            onClick={() => {
              setIsModal(true)
            }}
          >
            <button className="convert convert-line convert-fixed animation fixed font-bold leading-relaxed tracking-widest text-white right-6 bottom-6 z-10 flex flex-col h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-lgreen-default shadow shadow-lg hover:bg-lgreen-light active:bg-lgreen-dark sm:right-8 sm:bottom-8 sm:h-28 sm:w-28 lg:right-16 lg:bottom-16">
              <p className="text-xl sm:text-2xl">LINE</p>
              <p className="text-sm sm:text-base">免費諮詢</p>
            </button>
          </a>
        )}
        <Footer />
      </div>
      {isModal && <Modal setIsModal={setIsModal} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  showTitleTemplate: PropTypes.bool,
}

export default Layout
