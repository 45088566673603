import React from "react"
import PropTypes from "prop-types"
import { XIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"

const Modal = ({ setIsModal }) => {
  return (
    <>
      <div
        className="fixed inset-0 z-20 bg-black opacity-50"
        onClick={() => {
          setIsModal(false)
        }}
      />
      <div className="fixed top-1/2 modal -translate-y-1/2 max-w-lg z-20">
        <div className="p-6 flex flex-col items-center bg-white lg:p-12">
          <div className="w-full flex justify-between items-center pb-5">
            <p className="text-xl font-bold text-neutral-800 sm:text-2xl">
              LINE 免費諮詢
            </p>
            <button
              onClick={() => {
                setIsModal(false)
              }}
            >
              <XIcon className="block h-6 w-8 translate-x-1.5" />
            </button>
          </div>
          <div className="pt-5 pb-8  border-t border-t-neutral-400 sm:border-b sm:border-b-neutral-400 sm:pb-5">
            <p className="text-center text-neutral-800 sm:hidden">
              如有任何培訓需求，歡迎點擊下方「立即諮詢」按鈕加入言果學習line@帳號，或來電
              <a href="tel:+886-2-2596-1336" className="underline px-0.5">
                02-2596-1336
              </a>
              免費諮詢！
            </p>
            <p className="hidden text-center text-neutral-800 sm:text-lg sm:block">
              如有任何培訓需求，歡迎加入言果學習line@帳號，或來電
              <a href="tel:+886-2-2596-1336" className="underline pl-0.5">
                02-2596-1336
              </a>
              ，立即免費諮詢！
            </p>
            <div className="hidden sm:block sm:flex sm:justify-center sm:my-5">
              <StaticImage
                src="../images/img_言果_qrcode.png"
                alt="img_言果_qrcode"
                width={240}
                height={240}
                quality={100}
              />
            </div>
            <p className="hidden text-center text-neutral-800 sm:text-lg sm:block">
              LINE ID : @632ojaki
            </p>
          </div>
          <div>
            <a
              href={process.env.GATSBY_LINE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="inline-flex bg-amber-400 text-white justify-center items-center rounded whitespace-nowrap mr-6 py-2.5 px-5 font-bold sm:px-8 sm:text-lg sm:hidden hover:bg-amber-500 active:bg-amber-600">
                立即諮詢
              </button>
            </a>
            <button
              onClick={() => {
                setIsModal(false)
              }}
              className="inline-flex bg-white text-amber-400 border-2 border-amber-400 justify-center items-center rounded whitespace-nowrap py-2.5 px-5 font-bold sm:px-8 sm:text-lg sm:hidden hover:text-amber-500 hover:border-amber-500 active:text-amber-600 active:border-amber-600"
            >
              關閉
            </button>
            <button
              onClick={() => {
                setIsModal(false)
              }}
              className="inline-flex bg-amber-400 text-white justify-center items-center rounded whitespace-nowrap py-2.5 px-5 font-bold hidden sm:px-8 sm:text-lg sm:inline-flex sm:mt-5 hover:bg-amber-500 active:bg-amber-600"
            >
              關閉
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

Modal.propTypes = {
  setIsModal: PropTypes.func,
}

export default Modal
