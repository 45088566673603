import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { XIcon } from "@heroicons/react/solid"
import MagnifyingGlass from "../images/magnifying-glass.svg"

const SearchBar = ({ isSearchBarOpen, setIsSearchBarOpen }) => {
  const [searchQuery, setSearchQuery] = useState("")

  const inputRef = useRef(null)

  return (
    <div className="flex justify-center items-center w-full">
      <form
        action="/search"
        method="get"
        autoComplete="off"
        className="grow w-full"
      >
        <div
          className={`flex items-center bg-neutral-100 py-3 px-6 rounded ${
            isSearchBarOpen ? "border border-neutral-300" : ""
          }`}
        >
          <img
            src={MagnifyingGlass}
            alt="magnifying-glass"
            className="w-6 h-6 mr-4"
          />
          <input
            value={searchQuery}
            onInput={e => setSearchQuery(e.target.value)}
            ref={inputRef}
            type="text"
            id="header-search"
            placeholder="搜尋課程"
            name="s"
            className="w-full px-3 text-xl h-8 rounded-l !rounded-r-none bg-neutral-100"
          />
        </div>
      </form>
      <button
        className="sm:ml-14 xl:hidden"
        onClick={() => {
          setIsSearchBarOpen(prev => !prev)
        }}
        type="button"
      >
        <XIcon className="block h-6 w-8 translate-x-4" />
      </button>
    </div>
  )
}

SearchBar.propTypes = {
  isSearchBarOpen: PropTypes.bool,
  setIsSearchBarOpen: PropTypes.func,
}

export default SearchBar
