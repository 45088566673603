import React, { useState } from "react"
import PropTypes from "prop-types"
import FooterLogo from "../images/img_logo_vertical.svg"
import FooterDesktopLogo from "../images/img_logo_horizontal_footer.svg"
import Link from "./Link"
import { useForm } from "react-hook-form"
import createGoogleSheetDataAPI from "../functions/createGoogleSheetDataAPI"
import HelpText from "./HelpText"

const FooterSubMenu = ({ data, curId }) => {
  const length = data.length

  return data.map(({ title, url }, idx) => (
    <FooterMenuLink
      key={`footer-submenu-${curId}-${idx}`}
      title={title}
      url={url}
      isLastItem={idx === length - 1}
    />
  ))
}

const FooterMenuLink = ({ title, url, isLastItem }) => (
  <p
    className={`leading-normal hover:underline hover:underline-offset-4 ${
      isLastItem ? "" : "pb-2"
    }`}
  >
    <Link to={url} as="url">
      {title}
    </Link>
  </p>
)

const Footer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [isHelpText, setIsHelpText] = useState("")

  const onSubmit = async data => {
    try {
      const { email } = data

      const inboundBody = {
        time: new Date().toLocaleString("zh-TW"),
        email,
      }

      const result = await createGoogleSheetDataAPI(
        "https://script.google.com/macros/s/AKfycbw61QQI24ICjCxRNTkEepnsNHJ8aX62b0oy1M164e2ezz_LxUq3q1voMJPKRGihdBH6kw/exec",
        inboundBody
      )

      if (result.status === 200) {
        await reset()
        setIsHelpText("訂閱成功")
      } else {
        setIsHelpText("發生錯誤! 請加line聯繫我們")
      }
    } catch (e) {
      alert("發生錯誤! 請加line聯繫我們")
    }
  }

  const footerData = [
    {
      title: "COMPANY",
      info: [
        {
          title: "關於我們",
          url: "/about",
        },
        {
          title: "隱私權聲明",
          url: "/privacy",
        },
        // {
        //   title: "FAQ",
        //   url: "/", // Todo 等客戶提供連結
        // },
      ],
    },
    {
      title: "SERVICES",
      info: [
        {
          title: "企業內訓",
          url: "/corporate-training-service",
        },
        {
          title: "講師陣容",
          url: "/teacher",
        },
        {
          title: "線上課程",
          url: "/online-course-service",
        },
        {
          title: "課程開發",
          url: "/curriculum-development-service",
        },
        {
          title: "教學遊戲化",
          url: "/teaching-gamification-service",
        },
      ],
    },
    {
      title: "GET INVOLVED",
      info: [
        {
          title: "講師合作",
          url: "/lecturer-cooperation",
        },
        // {
        //   title: "方案許願池",
        //   url: "/", // Todo 等客戶提供連結
        // },
      ],
    },
  ]

  const contactInfo = {
    phoneNumber: "(02)2596-1336",
    email: "service@yanguo.com.tw",
    address: "103台北市大同區承德路三段232號7樓",
  }

  const { phoneNumber, email, address } = contactInfo

  const footerMenu = footerData.map(({ title, info }, idx) => (
    <div
      key={`footer-block-${idx}`}
      className="sm:pt-10 lg:pt-0 lg:border-none"
    >
      <p className="pb-3 whitespace-nowrap leading-loose font-bold text-amber-400">
        {title}
      </p>
      <FooterSubMenu data={info} curId={idx} />
    </div>
  ))

  return (
    <div className="bg-neutral-700 py-12 sm:pb-11 lg:pb-12 lg:pt-0">
      <div className="container mx-auto px-6 sm:px-16">
        <div className="lg:grid lg:grid-cols-12 lg:pb-16 lg:pt-12 lg:gap-8">
          <div className="lg:col-span-6">
            <img
              src={FooterLogo}
              alt="YANGUO footer logo"
              className="h-24 mb-8 mx-auto sm:h-32 lg:hidden"
            />
            <img
              src={FooterDesktopLogo}
              alt="YANGUO footer logo"
              className="hidden h-14 mb-12 mr-auto lg:inline"
            />
            <div className="text-white border-b pb-12 text-lg text-center lg:text-left lg:border-0 lg:pb-0">
              <p>{phoneNumber}</p>
              <a className="pt-2 underline" href={`mailto:${email}`}>
                {email}
              </a>
              <p className="pt-2">{address}</p>
            </div>
          </div>
          <div className="pt-8 grid gap-8 text-lg text-white text-center sm:pt-0 sm:grid sm:grid-cols-3 lg:col-span-6 lg:text-left">
            {footerMenu}
          </div>
        </div>
        <div className="lg:border-b lg:border-neutral-400">
          {/* 訂閱區塊 */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-12 pb-12 border-b border-neutral-400 sm:mt-16 sm:pb-16 lg:w-1/2 lg:ml-auto lg:border-none lg:mt-0"
          >
            <div className="flex flex-nowrap">
              <input
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "信箱格式錯誤",
                  },
                })}
                placeholder="請輸入E-mail"
                className="text-neutral-400 w-full py-2.5 pl-6 border-none outline-none rounded-l-lg"
              />
              <button
                type="submit"
                className="bg-amber-400 text-white font-bold rounded-r-lg"
              >
                <p className="px-4 py-2.5 w-28 whitespace-nowrap sm:px-12 sm:w-44 lg:py-3">
                  訂閱電子報
                </p>
              </button>
            </div>
            {errors?.email?.type === "required" && (
              <HelpText text="此為必填項目" />
            )}
            {errors?.email?.type === "pattern" && (
              <HelpText text={errors.email.message} />
            )}
            <HelpText text={isHelpText} />
          </form>
        </div>
        <div className="text-neutral-400 mt-8">
          <div className="whitespace-nowrap pb-4 text-center leading-normal flex flex-col justify-center items-center sm:flex-row ">
            <p>Copyright © {new Date().getFullYear()} 言果學習股份有限公司</p>
            <p className="sm:ml-1">All rights reserved.</p>
          </div>
          <p className="text-sm leading-normal text-center">
            <Link
              as="a"
              to="https://welly.tw/"
              style="underline underline-offset-1"
            >
              Powered by Welly SEO
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

FooterSubMenu.propTypes = {
  data: PropTypes.array,
  curId: PropTypes.number,
}

FooterMenuLink.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  isLastItem: PropTypes.bool,
}

export default Footer
