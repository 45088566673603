import React, { useState } from "react"
import Logo from "../images/img_logo_horizontal.svg"
import Link from "./Link"
import Button from "./Button"
import useHover from "../hooks/useHover"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid"
import PropTypes from "prop-types"
import { useLandingPageData } from "../data/useLandingPageData"
import { useModalUpdate } from "../contexts/ModalContext"
import SearchBar from "./SearchBar"
import MagnifyingGlass from "../images/magnifying-glass.svg"

const MenuIcons = ({ isOpen }) =>
  React.createElement(isOpen ? XIcon : MenuIcon, {
    className: "block h-6 w-8 translate-x-4",
  })

const ChevronIcons = ({ isOpen }) =>
  React.createElement(isOpen ? ChevronUpIcon : ChevronDownIcon, {
    className: "h-6 w-6 text-amber-400",
  })

const MobileMenuLink = ({ children, url }) => (
  <div className="text-lg tracking-wide font-bold text-neutral-600 py-5">
    <Link to={url} as={url.includes("https") ? "url" : "link"}>
      {children}
    </Link>
  </div>
)

const DesktopMenuLink = ({ children, url }) => (
  <div className="inline-flex items-center  tracking-wide text-neutral-600">
    <Link to={url} as={url.includes("https") ? "url" : "link"}>
      {children}
    </Link>
  </div>
)

const MobileSubmenu = ({ result }) => {
  const { title, url } = result
  return (
    <div className="py-2">
      <div className="flex flex-col space-y-4">
        <Link to={url}>
          <p className="leading-normal tracking-wide text-neutral-500 text-base">
            ⁃ {title}
          </p>
        </Link>
      </div>
    </div>
  )
}

const DesktopSubmenu = ({ result }) => {
  const { title, url } = result
  return (
    <Link
      to={url}
      style="text-lg text-neutral-600 transition-all duration-300 ease-in-out hover:text-neutral-800 py-3"
    >
      {title}
    </Link>
  )
}

const MobileMenuIncludeSubmenu = ({ children, info, url, isMenuOpen }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const submenu = info.map((result, index) => (
    <MobileSubmenu key={`submenu-${index}`} result={result} />
  ))
  return (
    <>
      <div className="py-5 text-lg tracking-wide font-bold text-neutral-600  flex justify-between">
        <div className="w-1/2">
          <Link to={url}>{children}</Link>
        </div>
        <div
          className="inline-flex justify-end w-12 my-auto"
          onClick={() => {
            isMenuOpen && setIsSubmenuOpen(prev => !prev)
          }}
        >
          <ChevronIcons isOpen={isSubmenuOpen} />
        </div>
      </div>
      <div className={isSubmenuOpen ? "block py-5" : "hidden"}>{submenu}</div>
    </>
  )
}

const DesktopMenuIncludeSubmenu = ({ children, info }) => {
  const [hoverRef, isHovered] = useHover()
  const subMenu = info.map((result, index) => (
    <DesktopSubmenu
      key={`subMenu-${index}`}
      result={result}
      isHovered={isHovered}
    />
  ))
  return (
    <div
      ref={hoverRef}
      className="relative block items-center hover:text-neutral-600"
    >
      <div className="flex items-center tracking-widest h-full">
        <p>{children}</p>
        <ChevronDownIcon className="h-6 w-6 text-amber-400" />
      </div>
      <div
        className={`absolute w-40 -left-3/4 top-[58px] transition-all duration-300 ease-in-out ${
          isHovered
            ? "h-48 z-10 border-yellow-400 border-t-4"
            : "h-0 -z-50 border-transparent"
        }`}
      >
        <div
          className={`border-b border-l border-r border-neutral-400 transition-all duration-300 ease-in-out grid bg-white text-center py-2.5 pb-3 ${
            isHovered ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          {subMenu}
        </div>
      </div>
    </div>
  )
}

const MobileMenuItem = ({ url, info, isInfoExist, children, isMenuOpen }) =>
  React.createElement(
    isInfoExist ? MobileMenuIncludeSubmenu : MobileMenuLink,
    isInfoExist
      ? {
          url: url,
          info: info,
          isMenuOpen: isMenuOpen,
        }
      : { url: url },
    children
  )

const MobileMenu = ({ menuInfo, isMenuOpen }) =>
  menuInfo.map(({ title, url, info }, index) => {
    const isInfoExist = info !== undefined
    return (
      <MobileMenuItem
        key={`menuInfo-${index}`}
        url={url}
        info={info}
        isInfoExist={isInfoExist}
        isMenuOpen={isMenuOpen}
      >
        {title}
      </MobileMenuItem>
    )
  })

const DesktopMenuItem = ({ url, info, isInfoExist, children }) =>
  React.createElement(
    isInfoExist ? DesktopMenuIncludeSubmenu : DesktopMenuLink,
    isInfoExist
      ? {
          url: url,
          info: info,
        }
      : { url: url },
    children
  )

const DesktopMenu = ({ menuInfo }) =>
  menuInfo.map(({ title, url, info }, index) => {
    const isInfoExist = info !== undefined
    return (
      <React.Fragment key={`DesktopMenuLink-${index}`}>
        <DesktopMenuItem url={url} info={info} isInfoExist={isInfoExist}>
          {title}
        </DesktopMenuItem>
      </React.Fragment>
    )
  })

const Navbar = ({ isModal }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)

  const { coursePlanning } = useLandingPageData()
  const setIsModal = useModalUpdate()

  const serviceData = coursePlanning.map(({ title, slug }) => {
    return { title, url: `/${slug}` }
  })

  const menuInfo = [
    {
      title: "關於",
      url: "/about",
    },
    {
      title: "服務",
      info: serviceData,
      url: serviceData[0].url,
    },
    {
      title: "課程",
      info: [
        { title: "找課程", url: "/course" },
        { title: "找講師", url: "/teacher" },
        { title: "找線上課", url: "/online-course" },
      ],
      url: "/course",
    },
    {
      title: "報名",
      url: "https://store.yanguo.com.tw/?affcode=web",
    },
    {
      title: "文章",
      info: [
        { title: "培訓新知", url: "/training-news" },
        { title: "企業案例", url: "/enterprise-case" },
      ],
      url: "/training-news",
    },
  ]
  return (
    <>
      <div
        className={`fixed duration-300 z-30 ease-in-out ${
          isSearchBarOpen
            ? "opacity-100 inset-0 bg-black bg-opacity-50"
            : "opacity-0 pointer-events-none"
        }`}
      />
      <div
        className={`w-full border-b border-neutral-400 z-30 bg-white ${
          isModal ? "" : "fixed"
        }`}
      >
        {isSearchBarOpen && (
          <div className="wrapper h-20 flex items-center">
            <SearchBar
              isSearchBarOpen={isSearchBarOpen}
              setIsSearchBarOpen={setIsSearchBarOpen}
            />
          </div>
        )}
        <div className={`wrapper ${isSearchBarOpen ? "hidden" : ""}`}>
          <div className="flex justify-between items-center h-20">
            <div className="flex gap-16">
              <Link to="/">
                <img
                  src={Logo}
                  alt="logo"
                  height={36}
                  width={159.5}
                  decoding="async"
                  loading="lazy"
                  className="lg:h-10"
                />
              </Link>
              <div className="hidden text-xl font-bold text-neutral-600 gap-10 xl:flex">
                <DesktopMenu menuInfo={menuInfo} />
              </div>
            </div>
            <div
              className={`absolute duration-300 ease-in-out top-20 z-10 left-0 right-0 xl:hidden
                ${
                  isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
                }`}
            >
              <div className="z-30 min-h-md">
                <div className="bg-white z-20 min-h-md py-3">
                  <div className="container mx-auto px-6 divide-y-2 divide-neutral-300">
                    <MobileMenu menuInfo={menuInfo} isMenuOpen={isMenuOpen} />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden text-xl font-bold text-neutral-600 gap-6 xl:flex">
              <SearchBar
                isSearchBarOpen={isSearchBarOpen}
                setIsSearchBarOpen={setIsSearchBarOpen}
              />
              <Button size="md" isLink={false} onClick={setIsModal}>
                立即諮詢
              </Button>
            </div>
            {/* 手機平板搜尋鈕 */}
            <div className="xl:hidden">
              <button
                onClick={() => {
                  setIsSearchBarOpen(prev => !prev)
                }}
              >
                <img
                  src={MagnifyingGlass}
                  alt="magnifying-glass"
                  className="w-6 h-6 mr-4"
                />
              </button>
              <button
                onClick={() => setIsMenuOpen(prev => !prev)}
                className="px-2 py-3 self-center hover:bg-auto"
                type="button"
                aria-label="menuIcon"
              >
                <MenuIcons isOpen={isMenuOpen} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed duration-300 ease-in-out xl:hidden ${
          isMenuOpen
            ? "opacity-100 z-20 inset-0 bg-gray-800 bg-opacity-50"
            : "opacity-0 pointer-events-none"
        }`}
      />
    </>
  )
}

MenuIcons.propTypes = {
  isOpen: PropTypes.bool,
}
ChevronIcons.propTypes = {
  isOpen: PropTypes.bool,
}
MobileMenuLink.propTypes = {
  children: PropTypes.any,
  url: PropTypes.string,
}
DesktopMenuLink.propTypes = {
  children: PropTypes.any,
  url: PropTypes.string,
}
MobileMenuIncludeSubmenu.propTypes = {
  children: PropTypes.string,
  info: PropTypes.array,
  url: PropTypes.string,
  isMenuOpen: PropTypes.bool,
}
MobileSubmenu.propTypes = {
  result: PropTypes.object,
  isSubmenuOpen: PropTypes.bool,
}
DesktopMenuIncludeSubmenu.propTypes = {
  children: PropTypes.string,
  info: PropTypes.array,
}
DesktopSubmenu.propTypes = {
  result: PropTypes.object,
}
DesktopMenuItem.propTypes = {
  url: PropTypes.string,
  info: PropTypes.array,
  isInfoExist: PropTypes.bool,
  children: PropTypes.string,
}
MobileMenuItem.propTypes = {
  url: PropTypes.string,
  info: PropTypes.array,
  isInfoExist: PropTypes.bool,
  children: PropTypes.string,
  isMenuOpen: PropTypes.bool,
}

Navbar.propTypes = {
  isModal: PropTypes.bool,
}
export default Navbar
