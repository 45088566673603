import React from "react"
import Link from "./Link"
import PropTypes from "prop-types"

const buttonColor = {
  yellow:
    "ease-in-out duration-300 bg-amber-400 text-white hover:bg-amber-500 active:bg-amber-600",
  white:
    "ease-in-out duration-300 bg-white text-amber-400 border-2 border-amber-400 hover:text-amber-500 hover:border-amber-500 active:text-amber-600 active:border-amber-600",
}

const buttonSize = {
  xs: "py-2.5 px-5 font-bold lg:px-8 lg:text-lg",
  sm: "py-2.5 px-5 font-bold sm:px-8 sm:text-lg",
  base: "py-2.5 px-8 text-lg font-bold sm:py-3 sm:px-14 sm:text-2xl",
  md: "py-3 px-10 text-xl font-bold",
}

const Button = ({
  children,
  to,
  as,
  color = "yellow",
  size = "base",
  style = "",
  isLink = true,
  onClick,
}) =>
  isLink ? (
    <Link
      to={to}
      as={as}
      style={`inline-flex justify-center items-center rounded whitespace-nowrap ${buttonColor[color]} ${style}`}
    >
      <div className={buttonSize[size]}>{children}</div>
    </Link>
  ) : (
    <button
      className={`inline-flex justify-center items-center rounded whitespace-nowrap ${buttonColor[color]} ${style}`}
      onClick={onClick}
    >
      <div className={buttonSize[size]}>{children}</div>
    </button>
  )

Button.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
  as: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.string,
  isLink: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Button
