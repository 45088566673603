import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

const Link = ({
  children,
  style,
  activeStyle,
  to: destination,
  as = "link",
}) => {
  if (as === "link") {
    return (
      <GatsbyLink
        to={destination}
        className={style}
        activeClassName={activeStyle}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a
      href={destination}
      target="_blank"
      rel="noopener noreferrer"
      className={style}
    >
      {children}
    </a>
  )
}

Link.propTypes = {
  as: PropTypes.string,
  style: PropTypes.string,
  activeStyle: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
}
export default Link
