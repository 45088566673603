import React from "react"
import PropTypes from "prop-types"

const HelpText = ({ text }) => {
  return (
    <p className="text-lg tracking-wide mt-2 font-bold text-amber-400">
      {text}
    </p>
  )
}

HelpText.propTypes = {
  text: PropTypes.string,
}

export default HelpText
