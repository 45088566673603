import { graphql, useStaticQuery } from "gatsby"

export const useSettingData = () => {
  return useStaticQuery(
    graphql`
      {
        contentfulSetting {
          ogImage {
            file {
              url
            }
          }
        }
      }
    `
  )
}
